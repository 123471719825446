<!-- WoundHistoryModal.vue -->
<template>
  <!-- Modal Dialog -->
  <v-dialog v-model="woundHxModalState" width="650">
    <v-card style="position: relative; max-height: 80vh; overflow: hidden;">
      <!-- Modal Title -->
      <v-card-title
        class="pb-2"
        style="display: flex;
          justify-content: space-between;
          position: sticky;
          top: 0;
          background-color: rgb(233,233,234);
          z-index: 3;"
      >
        <div class="text-h5 text-medium-emphasis ps-2">
          Wound History
        </div>
        <v-btn icon @click="woundHxModalState = false">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>

      <!-- Subtitle with patient and wound Info -->
      <v-card-subtitle style="position: sticky; top: 55px; background-color: rgb(233,233,234); z-index: 3;">
        <div class="ps-3">
          <div class="text-subtitle-2">
            Patient: <span style="font-style: italic; font-weight: normal;">{{ selectedWoundPatientData.last_name }}, {{ selectedWoundPatientData.first_name }}</span>
            <br>DOB: <span style="font-style: italic; font-weight: normal;">{{ formatDate(selectedWoundPatientData.dob) }}</span>
            <br>
          </div>
          <div class="woundType">
            {{ getWoundType(selectedWound.practice_type_id) }}
            <span v-if="selectedWound.location && selectedWound.location.location_text">
                  | {{ selectedWound.location.location_text }}
              </span>
          </div>
        </div>
      </v-card-subtitle>

      <!-- Expansion Panels for Treatments -->
      <template v-if="woundHistoryTreatments && woundHistoryTreatments.length > 0">
        <v-expansion-panels style="overflow-y: auto; max-height: 60vh;">
          <v-expansion-panel v-for="(treatment, index) in woundHistoryTreatments" :key="index">
            <v-expansion-panel-header
              @click="selectTreatment(treatment.id)"
              :class="{'selected-item': treatment.id === selectedTreatmentId}"
              style="position: sticky; top: 0px; background-color: white; z-index: 2;">
              {{ `Date of service: ${formatDate(treatment.encounter.visit_date)} (Signed by: ${providerName(treatment.encounter.created_by_user_id)})` }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- Details for each Treatment -->
              <!-- Etiology -->
              <template v-if="selectedWound.practice_type_id === 1">
                <NoteSection label="Etiology">
                  <text-field :value="getEtiologyText(treatment)" class="col-sm-12" readonly></text-field>
                </NoteSection>

                <!-- Wound Progress -->
                <NoteSection label="Wound Progress">
                  <v-textarea :value="getWoundProgress(treatment)" class="col-sm-12" rows="2" auto-grow outlined readonly no-resize></v-textarea>
                </NoteSection>

                <!-- Wound Measurements -->
                <NoteSection label="Wound Measurements">
                  <text-field :value="treatment.wound_treatment.wound_treatment_wound_size.primary_length" label="Pre length" class="col-sm-4" readonly label-top suffix="cm"></text-field>
                  <text-field :value="treatment.wound_treatment.wound_treatment_wound_size.primary_width" label="Pre width" class="col-sm-4" readonly label-top suffix="cm"></text-field>
                  <text-field :value="treatment.wound_treatment.wound_treatment_wound_size.primary_depth" label="Pre depth (cm)" class="col-sm-4" readonly label-top></text-field>
                  <text-field :value="treatment.wound_treatment.wound_treatment_wound_size.post_debridement_length" label="Post length" class="col-sm-4" readonly label-top suffix="cm"></text-field>
                  <text-field :value="treatment.wound_treatment.wound_treatment_wound_size.post_debridement_width" label="Post width" class="col-sm-4" readonly label-top suffix="cm"></text-field>
                  <text-field :value="treatment.wound_treatment.wound_treatment_wound_size.post_debridement_depth" label="Post depth (cm)" class="col-sm-4" readonly label-top></text-field>
                </NoteSection>

                <!-- Tissue Types -->
                <NoteSection label="Tissue Types">
                  <text-field :value="treatment.wound_treatment.wound_treatment_wound_size.slough" label="Slough" class="col-sm-3" suffix="%" readonly label-top></text-field>
                  <text-field :value="treatment.wound_treatment.wound_treatment_wound_size.granulation" label="Granulation" class="col-sm-3" suffix="%" readonly label-top></text-field>
                  <text-field :value="treatment.wound_treatment.wound_treatment_wound_size.necrotic_tissue" label="Necrotic" class="col-sm-3" suffix="%" readonly label-top></text-field>
                  <text-field :value="treatment.wound_treatment.wound_treatment_wound_size.hyper_granulation" label="Hypergranulation" class="col-sm-3" suffix="%" readonly label-top></text-field>
                  <text-field :value="treatment.wound_treatment.wound_treatment_wound_size.eschar" label="Eschar" class="col-sm-3" suffix="%" readonly label-top></text-field>
                  <text-field :value="treatment.wound_treatment.wound_treatment_wound_size.epithelial" label="Epithelial" class="col-sm-3" suffix="%" readonly label-top></text-field>
                  <text-field :value="treatment.wound_treatment.wound_treatment_wound_size.dermal" label="Dermal" class="col-sm-3" suffix="%" readonly label-top></text-field>
                </NoteSection>

                <!-- Dressings -->
                <NoteSection label="Dressings">
                  <text-field :value="treatment.wound_treatment.dressing_used_1" label="Dressing 1" class="col-sm-4" readonly label-top></text-field>
                  <text-field :value="treatment.wound_treatment.dressing_used_2" label="Dressing 2" class="col-sm-4" readonly label-top></text-field>
                  <text-field :value="treatment.wound_treatment.dressing_used_other" label="Dressing 3" class="col-sm-4" readonly label-top></text-field>
                </NoteSection>

                <!-- Procedure -->
                <NoteSection label="Procedure">
                  <v-textarea :value="procedureName(treatment.procedure_id)" class="col-sm-12" rows="2" auto-grow outlined readonly no-resize></v-textarea>
                </NoteSection>

                <!-- Reason for ongoing debridement -->
                <NoteSection label="Reason for ongoing debridement">
                  <v-textarea :value="getOngoingDebrReason(treatment)" class="col-sm-12" rows="2" auto-grow outlined readonly no-resize></v-textarea>
                </NoteSection>

                <!-- Reason for no debridement -->
                <NoteSection label="Reason for no debridement">
                  <v-textarea :value="toListCommaAnd(treatment.wound_treatment.debridement_not_performed_reasons)" class="col-sm-12" rows="2" auto-grow outlined readonly no-resize></v-textarea>
                  <v-textarea v-if="treatment.wound_treatment.debridement_not_performed_reason_other" :value="treatment.wound_treatment.debridement_not_performed_reason_other" label="Other" class="col-sm-12" rows="2" auto-grow outlined readonly no-resize></v-textarea>
                </NoteSection>

                <!-- Preoperative indications -->
                <NoteSection label="Preoperative indications">
                  <v-textarea :value="toListCommaAnd(treatment.wound_treatment.preoperative_indications)" class="col-sm-12" rows="2" auto-grow outlined readonly no-resize></v-textarea>
                  <v-textarea v-if="treatment.wound_treatment.preoperative_indication_other" :value="treatment.wound_treatment.preoperative_indication_other" label="Other" class="col-sm-12" rows="2" auto-grow outlined readonly no-resize></v-textarea>
                </NoteSection>

                <!-- Note in wound desc -->
                <NoteSection label="Wound Description">
                  <v-textarea :value="treatment.wound_treatment.wound_description_summary" class="col-sm-12" rows="2" auto-grow outlined readonly no-resize></v-textarea>
                </NoteSection>

                <!-- Operative note -->
                <NoteSection label="Operative Note">
                  <v-textarea :value="treatment.operative_note" class="col-sm-12" rows="2" auto-grow outlined readonly no-resize></v-textarea>
                </NoteSection>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <!-- No history found alert -->
      <template v-else>
        <div class="alert-message-container">
          <alert color="warning">
            Wound has no previous treatments.
          </alert>
        </div>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'

export default {
  props: {
    selectedWound: {
      type: Object,
      default: {},
    },
    woundHxModalState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalWoundHxModalState: this.woundHxModalState,
      selectedTreatmentId: null,
      icons: {
        mdiClose,
      },
    }
  },
  computed: {
    selectedWoundPatientData() {
      return this.$store.getters['patients/getById'](this.selectedWound.patient_id)
    },

    woundHistoryTreatments() {
      if (!this.selectedWound || !this.selectedWound.treatments) {
        return false
      }

      // Filter and sort treatments
      return this.selectedWound.treatments
        .filter(treatment => treatment.encounter.is_signed) // Filter treatments where is_signed is true
        .slice() // Make a copy to avoid mutating original array
        .sort((a, b) => {
          // Sort by visit_date in descending order
          return new Date(b.encounter.visit_date) - new Date(a.encounter.visit_date)
        })
    },
  },
  watch: {
    selectedWound: {
      handler() {
        // Reset selectedTreatmentId to null whenever selectedWound changes
        this.selectedTreatmentId = null;
      },
      immediate: true, // Trigger the watcher immediately when the component is mounted
      deep: true // Watch nested properties of selectedWound if applicable
    },
    woundHxModalState(newValue) {
      this.internalWoundHxModalState = newValue;
    },
    internalWoundHxModalState(newValue) {
      this.$emit('update:wound-hx-modal-state', newValue);
    },
  },
  methods: {
    // Set selectedTreatment when expansion panel in wound history modal is clicked
    selectTreatment(treatmentId) {
      this.selectedTreatmentId = this.selectedTreatmentId === treatmentId ? null : treatmentId
    },

    getEtiologyText(treatment) {
      return treatment.etiology !== 'Other' ? treatment.etiology : treatment.etiology_other
    },

    getWoundProgress(treatment) {
      return treatment.wound_treatment.progress !== 'Other' ? treatment.wound_treatment.progress : treatment.wound_treatment.progress_other
    },

    getOngoingDebrReason(treatment) {
      return treatment.wound_treatment.ongoing_debridement_reason !== 'Other' ? treatment.wound_treatment.ongoing_debridement_reason : treatment.wound_treatment.ongoing_debridement_reason_other
    },

    toListCommaAnd (array) {
      return this.$custom.toListCommaAnd(array)
    },

    procedureName(procId) {
      return this.$store.getters['baseData/procedureFromId'](procId).replace(/\[\d+\]/, '').trim();
    },

    providerName(userId) {
      const user = this.$store.getters['users/getById'](userId)
      return `${user.first_name || ''} ${user.last_name || ''}`.trim()
    },

    formatDate(date) {
      return this.$date(date).format('MM/DD/YYYY')
    },

    getWoundType(practiceTypeId) {
      switch (practiceTypeId) {
        case 1:
          return 'Wound';
        case 6:
          return 'Dermatology';
        case 5:
          return 'G-Tube';
        default:
          return '';
      }
    }
  }
}
</script>

<style lang="scss">
.selected-item {
  background-color: #569eda !important;
}
.v-expansion-panel-header {
  transition: background-color 0.3s ease;
}
.v-expansion-panel-header:not(.selected-item):hover {
  background-color: #569eda !important;
}
.woundType {
  color: white;
  background-color: #569eda;
  padding: 5px;
  border-radius: 5px;
  box-sizing: border-box;
  display: inline-block;
  width: auto;
}
.alert-message-container {
  margin-top: 10px;
  padding: 10px;
}
</style>
